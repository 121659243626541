import React from 'react'
import SectionHeading from './SectionHeading'
import Box from './Box'
import Section from './Section'
import BoxHeading from './BoxHeading'
import BoxContent from './BoxContent'
import { boxData } from '../data/boxData'
import styles from '../styles/OurServices.modules.css'

const OurServices = (Props) => {
    return (
        <Section>
            <SectionHeading>Our Services</SectionHeading>
            <div className='row g-2 w-100 px-auto pb-4  '>
                {
                    boxData.map((item, key) => (
                        <Box>
                            <div className='m-2 border rounded box-hover-effect' style={{ height: `15rem` ,backgroundColor:`#f1f1f1`}}>
                                <BoxHeading>
                                    {item.heading}
                                </BoxHeading>
                                <BoxContent>
                                    {item.content}
                                </BoxContent>
                            </div>
                        </Box>
                    ))
                }
            </div>
        </Section>
    )
}

export default OurServices