import React from 'react'
import SpaceBetween from './SpaceBetween'
import Logo from '../assets/oldLogo.png'

// Navbar section that holds nav elements
const Navbar = (Props) => {
    return (
        // Navbar outer section
        <div className='px-5 d-flex m-0 border text-center border-secondary position-fixed z-3 w-100' style={{ backgroundColor:`#1565C0`,height: `12vh`, borderBottom: '2px solid #007bff',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.3)',}}>
            {/* compoenent to hold logo nav-element and theme button */}
            <SpaceBetween width = "100%">
                <img src={`${Logo}`} style={{height:`5rem`}} alt='Sunstrat-Brand-Image'></img>
                {/* component to hold buttons nav elements */}
                <SpaceBetween>
                    {/* <span  className='mx-2 px-3' >Option</span>
                    <span  className='mx-2 px-3'>Option</span>
                    <span  className='mx-2 px-3'>Option</span>
                    <span  className='mx-2 px-3'>Option</span>
                    <span  className='mx-2 px-3'>Option</span>
                    <span  className='mx-2 px-3'>Option</span> */}
                </SpaceBetween>
                {/* <div>Button</div> */}
            </SpaceBetween>
        </div>
    )
}

export default Navbar