import React from 'react'

const SectionHeading = (Props) => {
  return (
    <div className='text-center fw-bold my-3 z-2' style={{fontSize:`2rem`}}>
        {Props.children}
    </div>
  )
}

export default SectionHeading