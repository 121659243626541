import React from 'react'
import Logo from '../assets/oldLogo.png'
import FooterSvg from './FooterSvg'
import SocialBtn from './SocialBtn'
import telegram from '../assets/telegram.png'
import linkedin from '../assets/linkedin.png'
import fiverr from '../assets/fiverr.png'


const Footer = (Props) => {
  return (
    <div className='d-flex align-items-center flex-column justify-content-center pt-3 position-relative'>
      <img className='my-5' src={`${Logo}`} style={{ height: `4rem` }} alt='Sunstrat-Brand-Image'></img>
      <p>Precision-engineered solar designs for today, ensuring a sustainable tomorrow.</p>
      <div className='d-flex mt-4'>
        <SocialBtn social={fiverr} socialName={"fiverr"} />
        <SocialBtn social={telegram} socialName={"telegram"} />
        <SocialBtn social={linkedin} socialName={"linkedin"} />
      </div>
      <div className="mt-2">
        Mail us on: <a href="mailto:info@sunstrat.in" className="text-blue-600 hover:underline">info@sunstrat.in</a>
      </div>
      <div style={{ position: `absolute`, top: `22rem`, width: `100%`, overflow: `hidden`, lineHeight: `0` }}>
        <FooterSvg />
      </div>
      <p style={{ position: `absolute`, bottom: `-12rem`, color: `white` }}>© 2023 All Rights Reserved</p>
    </div>
  )
}

export default Footer;