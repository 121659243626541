import React from 'react'
import Section from './Section'
import bgImg from "../assets/banner.jpg"
import SpaceBetween from './SpaceBetween'
import Button from './Button'

const Hero = (Props) => {
    return (
            <div style={{
                backgroundImage: `url(${bgImg})`,
                backgroundSize: `cover`,
                backgroundPosition: `center`,
                height: `88vh`,
                position: `relative`,
                marginTop:`12vh`
            }}>
                <div style={{
                    backgroundColor: `black`,
                    opacity: 0.5,
                    height: `88vh`,
                    position: 'absolute',
                    width: `100%`,
                }} />
                <Section>
                    <div className={`d-flex flex-column justify-content-center align-items-center`} style={{ zIndex: `1` }}>
                        <h1
                            style={{
                                fontSize: '6rem',
                                background: 'linear-gradient(90deg, #DC026F 0%, #F1C201 50%, #154483 100%)',
                                WebkitBackgroundClip: 'text',
                                fontStyle: `italic`,
                                color: 'transparent'
                            }}> </h1>
                        <p className='text-center' style={{
                            fontSize: `3.5rem`, fontWeight: `600`, fontStyle: `italic`, width: `60%`, WebkitTextStroke: '1px #1565C0',
                            color: '#FFEB3B',
                            marginBottom:`8rem`
                        }}>
                            Powering Tomorrow with our Proposals/Permit  Designs
                        </p>
                    </div>
                </Section>
            </div>
    )
}

export default Hero