import React from 'react'

const FooterSvg = (props) => {
    return (
        <div className='position-relative'>
            <svg className='position-absolute d-block' width="100%" height="auto" viewBox="0 0 1440 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0.121094L98.0261 20.5136C159.266 33.2535 222.553 32.4366 283.444 18.1204C334.059 6.22017 386.417 3.62757 437.96 10.4693L476.714 15.6134C521.366 21.5404 566.615 21.4064 611.232 15.2149L637.298 11.5977C692.011 4.00518 747.63 6.04256 801.641 17.6177C867.779 31.7921 936.187 31.6347 1002.26 17.1563L1004.18 16.7364C1054.4 5.73023 1106.07 2.82073 1157.21 8.11791L1208.13 13.392C1242.29 16.9294 1276.71 16.9392 1310.87 13.4212L1440 0.121094V148.121H0V0.121094Z" fill="url(#paint0_linear_237_1872)" fill-opacity="0.8" />
                <defs>
                    <linearGradient id="paint0_linear_237_1872" x1="0" y1="74.1211" x2="1440" y2="74.1211" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0052D4" />
                        <stop offset="0.5" stop-color="#4364F7" />
                        <stop offset="0.681" stop-color="#5380F9" />
                        <stop offset="1" stop-color="#6FB1FC" />
                    </linearGradient>
                </defs>
            </svg>
            <svg className='position-absolute' width="100%" height="auto" viewBox="0 0 1440 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 40.6211L97.9977 15.7985C157.724 0.669817 220.551 3.12574 278.914 22.8706L308.771 32.9713C361.523 50.8178 418.909 49.2752 470.626 28.6204C523.098 7.66406 581.378 6.39161 634.715 25.0378L653.144 31.4803C707.048 50.325 765.813 49.9361 819.463 30.3797C866.386 13.2755 917.388 10.778 965.758 23.2159L993.746 30.4129C1052.44 45.5059 1114.39 41.8553 1170.9 19.9726C1221.3 0.459373 1276.14 -4.60235 1329.26 5.35701L1440 26.1211V152.121H0V40.6211Z" fill="url(#paint0_linear_237_1874)"/>
                <defs>
                    <linearGradient id="paint0_linear_237_1874" x1="0" y1="71.1211" x2="1440" y2="71.1211" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0052D4" />
                        <stop offset="0.5" stop-color="#4364F7" />
                        <stop offset="1" stop-color="#6FB1FC" />
                    </linearGradient>
                </defs>
            </svg>
            <svg width="100%" height="auto" viewBox="0 0 1440 149" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 21.5129L48.4195 11.3909C124.756 -4.56711 203.768 -2.42952 279.13 17.6325L316.327 27.5346C383.493 45.4149 454.358 43.8444 520.666 23.0059C587.959 1.85796 659.923 0.560892 727.934 19.2702L777.877 33.0089C830.591 47.5101 885.327 53.2674 939.905 50.0516L1027.47 44.8923C1058.75 43.0489 1089.8 38.2682 1120.19 30.6131L1154.68 21.9249C1236.24 1.38068 1321.67 1.7434 1403.05 22.9795L1440 32.6211V141.635L0 146.121V21.5129Z" fill="url(#paint0_linear_237_1875)" fill-opacity="0.5" />
                <defs>
                    <linearGradient id="paint0_linear_237_1875" x1="-75.5525" y1="65.375" x2="1748.71" y2="65.375" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0052D4" />
                        <stop offset="0.5" stop-color="#4364F7" />
                        <stop offset="1" stop-color="#6FB1FC" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}

export default FooterSvg