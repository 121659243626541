import React from 'react'
import Navbar from '../Components/Navbar'
import Hero from '../Components/Hero'
import OurServices from '../Components/OurServices'
import ContactUs from '../Components/ContactUs'
import Footer from '../Components/Footer'
import AboutUs from '../Components/AboutUs'


const Home = (props) => {
  return (
    <div className='d-flex flex-column' style={{
        scrollbarWidth:`0px`
    }}>
        <Navbar/>
        <Hero />
        <AboutUs />
        <OurServices />
        <ContactUs />
        <Footer />
    </div>
  )
}

export default Home