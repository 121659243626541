import React from 'react'

const SpaceBetween = (props) => {
  return (
    <div className='d-flex justify-content-between align-items-center' style={{width : `${props.width}`}}>
       {props.children}
    </div>
  )
}

export default SpaceBetween