import React, { useState } from 'react'
import Section from './Section'
import SectionHeading from './SectionHeading'
import InputElement from './InputElement'
import ReactLoading from 'react-loading';

const ContactUs = (Props) => {
  const [formdata, setFormData] = useState({ name: '', email: '', contact: '', comments: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleInputChange = (field, value) => {
    setFormData({ ...formdata, [field]: value })
    setSuccess('')
  }

  const handleFormSubmit = async (value) => {
    value.preventDefault();
    if (!formdata.name) {
      setError('required')
      return;
    }
    if (!formdata.email) {
      setError('Email is required');
      return;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formdata.email)) {
      setError('incorrect');
      return;
    }
    setError('')

    const dataToSend = {
      name: formdata.name,
      email: formdata.email,
      contact: formdata.contact,
      comments: formdata.comments,
    };

    try {
      setSuccess('loading')
      const response = await fetch('https://sunstratbackend.onrender.com/mail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {
        const result = await response.json();
        console.log('Form submitted successfully:', result);

        setFormData({ name: '', email: '', contact: '', comments: '' });
        setError('');
        setSuccess('success');
      } else {
        setSuccess('error');
      }
    } catch (error) {
      setSuccess('error');
    }

  }

  return (
    <div style={{ backgroundColor: `#f1f1f1` }}>

      <Section>
        <SectionHeading>
          Contact Us
        </SectionHeading>
        <form action="" className='w-100 d-flex flex-column justify-content-center align-items-center' onSubmit={handleFormSubmit}>
          <InputElement>
            <label htmlFor="name" className='py-1'>Name <span style={{ color: `red` }}>* {error === 'required' ? 'Name is required' : ''}</span></label>
            <input type='text' name='name' value={formdata.name} onChange={(e) => handleInputChange('name', e.target.value)} className='p-1' style={{
              padding: '0.5rem',
              fontSize: `0.9rem`,
              width: '100%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
            }}></input>
          </InputElement>
          <InputElement>
            <label htmlFor="email" className='py-1'>Email <span style={{ color: `red` }}>* {error && error !== 'required' ? error === 'incorrect' ? 'Invalid Email' : 'Email is required' : ''}</span></label>
            <input type='text' name='email' value={formdata.email} onChange={(e) => handleInputChange('email', e.target.value)} className='p-1' style={{
              padding: '0.5rem',
              fontSize: `0.9rem`,
              width: '100%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
            }}></input>
          </InputElement>
          <InputElement>
            <label htmlFor="number" className='py-1'>Contact</label>
            <input type='text' name='contact' value={formdata.contact} onChange={(e) => handleInputChange('contact', e.target.value)} className='p-1' style={{
              padding: '0.5rem',
              fontSize: `0.9rem`,
              width: '100%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
            }}></input>
          </InputElement>
          <InputElement>
            <label htmlFor="comments" className='py-1'>Comments</label>
            <textarea type='text' name='comments' value={formdata.comments} onChange={(e) => handleInputChange('comments', e.target.value)} className='p-1' style={{
              padding: '0.5rem',
              width: '100%',
              fontSize: `0.9rem`,
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
              resize: 'vertical',
            }}></textarea>
          </InputElement>
          <button className='w-25 py-1' style={{
            width: '25%',
            padding: '0.5rem',
            marginTop: '1rem',
            marginBottom: '2rem',
            backgroundColor: '#1565C0',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease',
          }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#1565C0'}
          >Submit</button>
          {success === 'loading' ? <ReactLoading className='position-absolute fixed-bottom mb-3 mx-auto' type={'spinningBubbles'} color={'grey'} height={18} width={18} /> : success === 'success' ? <label className='text-success position-absolute fixed-bottom mb-2 text-center'>Submitted</label> : success === 'error' ? <label className='text-danger position-absolute fixed-bottom mb-2 text-center'>An error occurred. Please try again.</label> : ''}


        </form>
      </Section>
    </div>
  )
}

export default ContactUs