import React from 'react'


const BoxHeading = (Props) => {
  return (
    <div className='text-center px-2 py-4 fs-3'>
        {Props.children}
    </div>
  )
}

export default BoxHeading