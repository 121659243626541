import React from 'react'

const InputElement = (Props) => {
  return (
    <div className='d-flex flex-column w-50 py-1'>
        {Props.children}
    </div>
  )
}

export default InputElement