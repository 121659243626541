import React from 'react'
import Section from './Section'
import SpaceBetween from './SpaceBetween'
import SectionHeading from './SectionHeading'
import BrandLogo from '../assets/oldLogo.png'

const AboutUs = (Props) => {
    return (
        <div style={{backgroundColor:`#f1f1f1`}}>

        <Section>
            <SectionHeading>About Us</SectionHeading>
            <SpaceBetween>
                <div className='d-flex justify-content-center' ><img src={`${BrandLogo}`} style={{ width: `60%`, height: `100%`, }} alt='Brand_Image'></img></div>
                <div className='w-100 fs-5 px-5' style={{ textAlign: 'justify', padding: `2rem` }}>We provide comprehensive solar design solutions, from residential to utility-scale projects. Our
                    services include structural and electrical design, solar permits, proposals, and custom solar
                    reports using leading industry software. With a quick turnaround time of under 24 hours, we
                    ensure that your projects run smoothly and on time.
                </div>
            </SpaceBetween>
        </Section>
        </div>
    )
}

export default AboutUs