import React from 'react'

const Box = (props) => {
  return(
    <div className='px-0 col-lg-4 col-md-6 col-xs-12'>
       {props.children}
    </div>
  )
}

export default Box