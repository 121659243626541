export const boxData = [
    {
        heading: "Solar Design",
        content: "Comprehensive solar design services for projects of all sizes, tailored to maximize efficiency and performance. From residential to utility-scale, we cover every aspect of solar PV design."
    },
    {
        heading: "Solar Permit",
        content: "Code-compliant permit packages designed to meet AHJ and utility standards, ensuring a smooth approval process for your solar installations."
    },
    {
        heading: "Solar Proposals",
        content: "Professional, client-ready proposals featuring system design, cost analysis, and ROI projections to help you close deals faster."
    },
    {
        heading: "Structural and Electrical Design",
        content: "Detailed structural and electrical designs for safe and optimized solar installations, meeting local codes and industry standards."
    },
    {
        heading: "Solar Reports",
        content: "Accurate solar performance reports using advanced software to assess energy yield, shading impact, and system losses, giving you a clear view of project potential."
    },
    {
        heading: "Solar System Installation",
        content: "End-to-end solar installation services, from site preparation to final system commissioning. Our experienced team ensures a safe, efficient, and high-quality installation for maximum performance."
    },
    {
        heading: "Remote/Site Assessments",
        content: "On-site and remote assessments to evaluate project feasibility, optimize layouts, and identify potential challenges ahead of installation."
    },
    {
        heading: "3D Modelling and Shading Analysis",
        content: "Advanced 3D modelling and shading analysis to visualize your solar system and minimize shading losses, maximizing system output and efficiency."
    },
    {
        heading: "Mechanical Drawings",
        content: "Expert 2D and 3D mechanical drawings for diverse applications. Our designs ensure precision, compliance, and optimal functionality for your engineering projects."
    },
];
